import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const AIInboxIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 11C14.9696 11 14.4608 10.7893 14.0858 10.4142C13.7107 10.0391 13.5 9.53043 13.5 9C13.5 8.73478 13.3946 8.48043 13.2071 8.29289C13.0196 8.10536 12.7652 8 12.5 8C12.2348 8 11.9804 8.10536 11.7929 8.29289C11.6054 8.48043 11.5 8.73478 11.5 9C11.5 9.53043 11.2893 10.0391 10.9142 10.4142C10.5391 10.7893 10.0304 11 9.5 11C9.23478 11 8.98043 11.1053 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13C10.0304 13 10.5391 13.2107 10.9142 13.5858C11.2893 13.9608 11.5 14.4696 11.5 15C11.5 15.2652 11.6054 15.5196 11.7929 15.7071C11.9804 15.8946 12.2348 16 12.5 16C12.7652 16 13.0196 15.8946 13.2071 15.7071C13.3946 15.5196 13.5 15.2652 13.5 15C13.5 14.4696 13.7107 13.9608 14.0858 13.5858C14.4608 13.2107 14.9696 13 15.5 13C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1053 15.7652 11 15.5 11Z"
      fill="currentColor"
    />
    <path
      d="M15.7792 3.45398C15.5898 3.45398 15.4081 3.37874 15.2742 3.24482C15.1403 3.1109 15.0651 2.92926 15.0651 2.73987C15.0651 2.64517 15.0274 2.55435 14.9605 2.48739C14.8935 2.42043 14.8027 2.38281 14.708 2.38281C14.6133 2.38281 14.5225 2.42043 14.4555 2.48739C14.3886 2.55435 14.351 2.64517 14.351 2.73987C14.351 2.92926 14.2757 3.1109 14.1418 3.24482C14.0079 3.37874 13.8262 3.45398 13.6368 3.45398C13.5421 3.45398 13.4513 3.4916 13.3844 3.55856C13.3174 3.62552 13.2798 3.71634 13.2798 3.81103C13.2798 3.90573 13.3174 3.99655 13.3844 4.06351C13.4513 4.13047 13.5421 4.16809 13.6368 4.16809C13.8262 4.16809 14.0079 4.24333 14.1418 4.37725C14.2757 4.51117 14.351 4.69281 14.351 4.8822C14.351 4.9769 14.3886 5.06772 14.4555 5.13468C14.5225 5.20164 14.6133 5.23926 14.708 5.23926C14.8027 5.23926 14.8935 5.20164 14.9605 5.13468C15.0274 5.06772 15.0651 4.9769 15.0651 4.8822C15.0651 4.69281 15.1403 4.51117 15.2742 4.37725C15.4081 4.24333 15.5898 4.16809 15.7792 4.16809C15.8739 4.16809 15.9647 4.13047 16.0317 4.06351C16.0986 3.99655 16.1362 3.90573 16.1362 3.81103C16.1362 3.71634 16.0986 3.62552 16.0317 3.55856C15.9647 3.4916 15.8739 3.45398 15.7792 3.45398Z"
      fill="currentColor"
    />
    <path
      d="M10.4743 1.97406C10.1253 1.97406 9.79053 1.83541 9.54372 1.5886C9.29692 1.3418 9.15826 1.00706 9.15826 0.65802C9.15826 0.483502 9.08894 0.316132 8.96554 0.192729C8.84213 0.0693263 8.67476 0 8.50024 0C8.32573 0 8.15836 0.0693263 8.03495 0.192729C7.91155 0.316132 7.84222 0.483502 7.84222 0.65802C7.84222 1.00706 7.70357 1.3418 7.45676 1.5886C7.20996 1.83541 6.87522 1.97406 6.52618 1.97406C6.35167 1.97406 6.1843 2.04339 6.06089 2.16679C5.93749 2.29019 5.86816 2.45756 5.86816 2.63208C5.86816 2.8066 5.93749 2.97397 6.06089 3.09737C6.1843 3.22077 6.35167 3.2901 6.52618 3.2901C6.87522 3.2901 7.20996 3.42875 7.45676 3.67556C7.70357 3.92236 7.84222 4.2571 7.84222 4.60614C7.84222 4.78066 7.91155 4.94803 8.03495 5.07143C8.15836 5.19483 8.32573 5.26416 8.50024 5.26416C8.67476 5.26416 8.84213 5.19483 8.96554 5.07143C9.08894 4.94803 9.15826 4.78066 9.15826 4.60614C9.15826 4.2571 9.29692 3.92236 9.54372 3.67556C9.79053 3.42875 10.1253 3.2901 10.4743 3.2901C10.6488 3.2901 10.8162 3.22077 10.9396 3.09737C11.063 2.97397 11.1323 2.8066 11.1323 2.63208C11.1323 2.45756 11.063 2.29019 10.9396 2.16679C10.8162 2.04339 10.6488 1.97406 10.4743 1.97406Z"
      fill="currentColor"
    />
    <path
      d="M11.1504 5.89795C11.0476 5.89795 10.949 5.8571 10.8762 5.7844C10.8035 5.71169 10.7627 5.61308 10.7627 5.51025C10.7627 5.45884 10.7423 5.40954 10.7059 5.37318C10.6696 5.33683 10.6203 5.31641 10.5688 5.31641C10.5174 5.31641 10.4681 5.33683 10.4318 5.37318C10.3954 5.40954 10.375 5.45884 10.375 5.51025C10.375 5.61308 10.3342 5.71169 10.2614 5.7844C10.1887 5.8571 10.0901 5.89795 9.9873 5.89795C9.93589 5.89795 9.88659 5.91837 9.85023 5.95473C9.81388 5.99108 9.79346 6.04039 9.79346 6.0918C9.79346 6.14321 9.81388 6.19251 9.85023 6.22887C9.88659 6.26522 9.93589 6.28564 9.9873 6.28564C10.0901 6.28564 10.1887 6.32649 10.2614 6.3992C10.3342 6.47191 10.375 6.57052 10.375 6.67334C10.375 6.72475 10.3954 6.77406 10.4318 6.81041C10.4681 6.84676 10.5174 6.86719 10.5688 6.86719C10.6203 6.86719 10.6696 6.84676 10.7059 6.81041C10.7423 6.77406 10.7627 6.72475 10.7627 6.67334C10.7627 6.57052 10.8035 6.47191 10.8762 6.3992C10.949 6.32649 11.0476 6.28564 11.1504 6.28564C11.2018 6.28564 11.2511 6.26522 11.2875 6.22887C11.3238 6.19251 11.3442 6.14321 11.3442 6.0918C11.3442 6.04039 11.3238 5.99108 11.2875 5.95473C11.2511 5.91837 11.2018 5.89795 11.1504 5.89795Z"
      fill="currentColor"
    />
    <path
      d="M7.9574 9.84167C7.768 9.84167 7.58637 9.76644 7.45244 9.63252C7.31852 9.49859 7.24329 9.31696 7.24329 9.12756C7.24329 9.03287 7.20567 8.94205 7.13871 8.87509C7.07175 8.80813 6.98093 8.77051 6.88623 8.77051C6.79153 8.77051 6.70071 8.80813 6.63375 8.87509C6.56679 8.94205 6.52917 9.03287 6.52917 9.12756C6.52917 9.31696 6.45394 9.49859 6.32002 9.63252C6.18609 9.76644 6.00446 9.84167 5.81506 9.84167C5.72037 9.84167 5.62955 9.87929 5.56259 9.94625C5.49563 10.0132 5.45801 10.104 5.45801 10.1987C5.45801 10.2934 5.49563 10.3842 5.56259 10.4512C5.62955 10.5182 5.72037 10.5558 5.81506 10.5558C6.00446 10.5558 6.18609 10.631 6.32002 10.7649C6.45394 10.8989 6.52917 11.0805 6.52917 11.2699C6.52917 11.3646 6.56679 11.4554 6.63375 11.5224C6.70071 11.5893 6.79153 11.627 6.88623 11.627C6.98093 11.627 7.07175 11.5893 7.13871 11.5224C7.20567 11.4554 7.24329 11.3646 7.24329 11.2699C7.24329 11.0805 7.31852 10.8989 7.45244 10.7649C7.58637 10.631 7.768 10.5558 7.9574 10.5558C8.05209 10.5558 8.14291 10.5182 8.20987 10.4512C8.27683 10.3842 8.31445 10.2934 8.31445 10.1987C8.31445 10.104 8.27683 10.0132 8.20987 9.94625C8.14291 9.87929 8.05209 9.84167 7.9574 9.84167Z"
      fill="currentColor"
    />
    <path
      d="M4.83826 12.9012C4.50952 12.9012 4.19425 12.7707 3.9618 12.5382C3.72935 12.3058 3.59875 11.9905 3.59875 11.6617C3.59875 11.4974 3.53346 11.3397 3.41723 11.2235C3.30101 11.1073 3.14337 11.042 2.979 11.042C2.81464 11.042 2.657 11.1073 2.54077 11.2235C2.42455 11.3397 2.35925 11.4974 2.35925 11.6617C2.35925 11.9905 2.22866 12.3058 1.99621 12.5382C1.76376 12.7707 1.44849 12.9012 1.11975 12.9012C0.955383 12.9012 0.797746 12.9665 0.68152 13.0828C0.565295 13.199 0.5 13.3566 0.5 13.521C0.5 13.6854 0.565295 13.843 0.68152 13.9592C0.797746 14.0755 0.955383 14.1407 1.11975 14.1407C1.44849 14.1407 1.76376 14.2713 1.99621 14.5038C2.22866 14.7362 2.35925 15.0515 2.35925 15.3802C2.35925 15.5446 2.42455 15.7023 2.54077 15.8185C2.657 15.9347 2.81464 16 2.979 16C3.14337 16 3.30101 15.9347 3.41723 15.8185C3.53346 15.7023 3.59875 15.5446 3.59875 15.3802C3.59875 15.0515 3.72935 14.7362 3.9618 14.5038C4.19425 14.2713 4.50952 14.1407 4.83826 14.1407C5.00262 14.1407 5.16026 14.0755 5.27649 13.9592C5.39271 13.843 5.45801 13.6854 5.45801 13.521C5.45801 13.3566 5.39271 13.199 5.27649 13.0828C5.16026 12.9665 5.00262 12.9012 4.83826 12.9012Z"
      fill="currentColor"
    />
    <path
      d="M4.1532 6.09265C3.9638 6.09265 3.78217 6.01741 3.64825 5.88349C3.51432 5.74957 3.43909 5.56793 3.43909 5.37854C3.43909 5.28384 3.40147 5.19302 3.33451 5.12606C3.26755 5.0591 3.17673 5.02148 3.08203 5.02148C2.98733 5.02148 2.89652 5.0591 2.82955 5.12606C2.76259 5.19302 2.72498 5.28384 2.72498 5.37854C2.72498 5.56793 2.64974 5.74957 2.51582 5.88349C2.3819 6.01741 2.20026 6.09265 2.01086 6.09265C1.91617 6.09265 1.82535 6.13027 1.75839 6.19723C1.69143 6.26419 1.65381 6.35501 1.65381 6.44971C1.65381 6.5444 1.69143 6.63522 1.75839 6.70218C1.82535 6.76914 1.91617 6.80676 2.01086 6.80676C2.20026 6.80676 2.3819 6.882 2.51582 7.01592C2.64974 7.14984 2.72498 7.33148 2.72498 7.52087C2.72498 7.61557 2.76259 7.70639 2.82955 7.77335C2.89652 7.84031 2.98733 7.87793 3.08203 7.87793C3.17673 7.87793 3.26755 7.84031 3.33451 7.77335C3.40147 7.70639 3.43909 7.61557 3.43909 7.52087C3.43909 7.33148 3.51432 7.14984 3.64825 7.01592C3.78217 6.882 3.9638 6.80676 4.1532 6.80676C4.2479 6.80676 4.33871 6.76914 4.40567 6.70218C4.47264 6.63522 4.51025 6.5444 4.51025 6.44971C4.51025 6.35501 4.47264 6.26419 4.40567 6.19723C4.33871 6.13027 4.2479 6.09265 4.1532 6.09265Z"
      fill="currentColor"
    />
    <path
      d="M1.85693 0.581543C1.75411 0.581543 1.6555 0.540697 1.58279 0.46799C1.51008 0.395283 1.46924 0.296671 1.46924 0.193848C1.46924 0.142436 1.44882 0.09313 1.41246 0.0567765C1.37611 0.020423 1.3268 0 1.27539 0C1.22398 0 1.17467 0.020423 1.13832 0.0567765C1.10197 0.09313 1.08154 0.142436 1.08154 0.193848C1.08154 0.296671 1.0407 0.395283 0.96799 0.46799C0.895283 0.540697 0.796671 0.581543 0.693848 0.581543C0.642436 0.581543 0.59313 0.601966 0.556777 0.638319C0.520423 0.674673 0.5 0.723979 0.5 0.775391C0.5 0.826802 0.520423 0.876108 0.556777 0.912462C0.59313 0.948815 0.642436 0.969238 0.693848 0.969238C0.796671 0.969238 0.895283 1.01008 0.96799 1.08279C1.0407 1.1555 1.08154 1.25411 1.08154 1.35693C1.08154 1.40835 1.10197 1.45765 1.13832 1.494C1.17467 1.53036 1.22398 1.55078 1.27539 1.55078C1.3268 1.55078 1.37611 1.53036 1.41246 1.494C1.44882 1.45765 1.46924 1.40835 1.46924 1.35693C1.46924 1.25411 1.51008 1.1555 1.58279 1.08279C1.6555 1.01008 1.75411 0.969238 1.85693 0.969238C1.90835 0.969238 1.95765 0.948815 1.994 0.912462C2.03036 0.876108 2.05078 0.826802 2.05078 0.775391C2.05078 0.723979 2.03036 0.674673 1.994 0.638319C1.95765 0.601966 1.90835 0.581543 1.85693 0.581543Z"
      fill="currentColor"
    />
  </SvgIcon>
);
