import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LazyLoadPage from "pages/LazyLoadPage/LazyLoadPage";

const AccountSettingsPage = lazy(() => import("pages/AccountSettingsPage"));
const AIInboxPage = lazy(() => import("pages/AIInboxPage"));
const DashboardPage = lazy(() => import("pages/DashboardPage"));
const ErrorPage = lazy(() => import("pages/ErrorPage"));
const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const SalesBookPage = lazy(() => import("pages/SalesBookPage"));
const SignUpPage = lazy(() => import("pages/SignUpPage"));
const TeamPage = lazy(() => import("pages/TeamPage"));
const TeamProfilePage = lazy(() => import("pages/TeamProfilePage"));
const SalesJournalPage = lazy(() => import("pages/SalesJournalPage"));

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/Home`} />} />
      <Route path={`*`} element={<LazyLoadPage children={<ErrorPage />} />} />
      <Route
        path={`/Home`}
        element={<LazyLoadPage children={<HomePage />} />}
      />
      <Route
        path={`/Team`}
        element={<LazyLoadPage children={<TeamPage />} />}
      />

      <Route
        path={`/Team/:TeamMemberId`}
        element={<LazyLoadPage children={<TeamProfilePage />} />}
      />
      <Route
        path={`/Sales-Journal`}
        element={<LazyLoadPage children={<SalesJournalPage />} />}
      />
      <Route
        path={`/AI-Inbox`}
        element={<LazyLoadPage children={<AIInboxPage />} />}
      />
      <Route
        path={`/Dashboard`}
        element={<LazyLoadPage children={<DashboardPage />} />}
      />
      <Route
        path={`/Sales-book`}
        element={<LazyLoadPage children={<SalesBookPage />} />}
      />
      <Route
        path={`/Login`}
        element={<LazyLoadPage children={<LoginPage />} />}
      />
      <Route
        path={`/SignUp`}
        element={<LazyLoadPage children={<SignUpPage />} />}
      />
      <Route
        path={`/Account-Settings`}
        element={<LazyLoadPage children={<AccountSettingsPage />} />}
      />
    </Routes>
  );
};

export default Routing;
