import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: 4,
    justifyContent: "center",
  },
  listItem:{
    display: "flex",
    justifyContent: "center",
    width: "100%",
  }
};
