import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    columnGap: 8,
    rowGap: 8,
  },
  listItem: {
    width: 266,
    minWidth: 266,
    maxWidth: 266,
  },
};
