import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    width: "100%",
    a: {
      textDecoration: "none",
      width: "100%",
    },
  },
  listItem: {
    display: "flex",
    columnGap: 3,
    px: 4,
    py: 1,
    width: "100%",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "flex-start",
    border: "1px solid transparent",
    svg: {
      color: basicTheme.palette.mainPalette.primaryBlack,
    },
    "&:hover": {
      borderColor: basicTheme.palette.mainPalette.lightSecondaryGrey,
      transition: ".3s ease-in-out",
    },
  },
  activeListItem: {
    bgcolor: basicTheme.palette.mainPalette.primaryBlack,
    svg: {
      color: basicTheme.palette.accentPalette.green1,
    },
    "&:hover": {
      borderColor: basicTheme.palette.mainPalette.primaryBlack,
    },
    ".MuiTypography-root": {
      color: basicTheme.palette.accentPalette.green1,
    },
  },
  shortListItem: {
    justifyContent: "center",
    width: "min-content",
    mx: "auto",
    px: 1,
  },
  title: {
    textDecoration: "none",
  },
  icon: {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: 1,
  },
};
