import React, { FC, ReactNode } from "react";

import { Link } from "react-router-dom";
import { Box } from "ui/Box";
import { Typography } from "ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface NavigationListItemProps {
  id: number;
  icon: ReactNode;
  route: string;
  title: string;
  active: boolean;
  long: boolean;
}

export const NavigationListItem: FC<NavigationListItemProps> = React.memo(
  ({ id, icon, route, title, active, long }) => {
    const listItemStyle = (active: boolean, long: boolean): SxProps => {
      if (active && long) {
        return {
          ...styles.listItem,
          ...styles.activeListItem,
          ...styles.longListItem,
        } as SxProps;
      } else if (!active && !long) {
        return {
          ...styles.listItem,
          ...styles.shortListItem,
        } as SxProps;
      } else if (active && !long) {
        return {
          ...styles.listItem,
          ...styles.activeListItem,
          ...styles.shortListItem,
        } as SxProps;
      } else {
        return styles.listItem;
      }
    };
    return (
      <Box sx={styles.root}>
        <Link to={route}>
          <Box sx={listItemStyle(active, long)}>
            <Box sx={styles.icon}>{icon}</Box>
            {long && (
              <Typography variant="h5" sx={styles.title}>
                {title}
              </Typography>
            )}
          </Box>
        </Link>
      </Box>
    );
  }
);
