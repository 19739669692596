import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  titleBox: {
    width: "100%",
    px: 2,
    py: 1,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    border: `1px solid ${basicTheme.palette.mainPalette.placeholderText}`,
    position: "relative",
    mb: 5,
  },
  dateTitle: {
    color: basicTheme.palette.mainPalette.placeholderText,
    textAlign: "center",
  },
  titleBoxActive: {
    width: "100%",
    px: 2,
    py: 1,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    position: "relative",
    mb: 5,
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      borderRadius: 2,
      background: basicTheme.palette.gradientsPalette.gradient4,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      left: "2px",
      top: "2px",
      transform: "translate(-1px, -1px)",
      width: "calc(100% - 2px)",
      height: "calc(100% - 2px)",
      borderRadius: 2,
      background: basicTheme.palette.mainPalette.white,
    },
  },
  dateTitleActive: {
    background: basicTheme.palette.gradientsPalette.gradient4,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    zIndex: 3,
    position: "relative",
    textAlign: "center",
  },
};
