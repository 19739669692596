import api from "api/axios";

const accountInfoApi = {
  getAccountInfo: async () => {
    try {
      const response = await api.get("users/self/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const filtersInfoApi = {
  getLayoutFilterInfo: async () => {
    try {
      const response = await api.get("layouts/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getUsersFilterInfo: async () => {
    try {
      const response = await api.get("users/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

const globalApiRequests = {
  accountInfoApi,
  filtersInfoApi,
};

export default globalApiRequests;
