import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import UserPlaceholder from "assets/user-placeholder-black.png";
import Image from "mui-image";
import { Box } from "ui/Box";
import { Typography } from "ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface UserProfileButtonProps {
  long: boolean;
  image: string;
  name: string;
  surname: string;
  companyName: string;
}

export const UserProfileButton: FC<UserProfileButtonProps> = React.memo(
  ({ long, image, name, surname, companyName }) => {
    const [buttonHover, setButtonHover] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = useState(false);

    useEffect(() => {
      setActive(location.pathname === "/Account-Settings");
    }, [location]);

    const rootStyles = (active: boolean, long: boolean) => {
      const rootStyle = long
        ? styles.root
        : ({ ...styles.root, ...styles.rootShort } as SxProps);
      return active
        ? ({ ...rootStyle, ...styles.rootActive } as SxProps)
        : rootStyle;
    };

    return (
      <Box
        sx={
          active
            ? ({ ...styles.container, ...styles.containerActive } as SxProps)
            : styles.container
        }
        onClick={() => navigate("/Account-Settings")}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
      >
        <Box sx={rootStyles(active, long)}>
          <Box
            sx={
              long
                ? styles.userImage
                : ({ ...styles.userImage, ...styles.shortUserImage } as SxProps)
            }
          >
            <Image
              src={image ? image : UserPlaceholder}
              duration={0}
              fit="cover"
            />
          </Box>
          {long && (
            <Box sx={styles.description}>
              <Box sx={styles.userNameBox}>
                <Typography
                  sx={
                    active
                      ? ({
                          ...styles.userName,
                          ...styles.userNameActive,
                        } as SxProps)
                      : buttonHover
                      ? styles.hoverUserName
                      : styles.userName
                  }
                >
                  {name}
                </Typography>
                <Typography
                  sx={
                    active
                      ? ({
                          ...styles.userName,
                          ...styles.userNameActive,
                        } as SxProps)
                      : buttonHover
                      ? styles.hoverUserName
                      : styles.userName
                  }
                >
                  {surname}
                </Typography>
              </Box>
              <Typography
                variant="caption"
                sx={
                  active
                    ? ({
                        ...styles.companyName,
                        ...styles.companyNameActive,
                      } as SxProps)
                    : buttonHover
                    ? styles.hoverCompanyName
                    : styles.companyName
                }
              >
                {companyName}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
