import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const SalesJournalIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3335 0H3.66683C2.7831 0.00105857 1.93587 0.352588 1.31098 0.97748C0.686084 1.60237 0.334555 2.4496 0.333496 3.33333V13.3333C0.333496 14.0406 0.614448 14.7189 1.11454 15.219C1.61464 15.719 2.29292 16 3.00016 16H10.3335C11.2172 15.9989 12.0645 15.6474 12.6893 15.0225C13.3142 14.3976 13.6658 13.5504 13.6668 12.6667V3.33333C13.6658 2.4496 13.3142 1.60237 12.6893 0.97748C12.0645 0.352588 11.2172 0.00105857 10.3335 0V0ZM12.3335 3.33333V10.6667H4.3335V1.33333H10.3335C10.8639 1.33333 11.3726 1.54405 11.7477 1.91912C12.1228 2.29419 12.3335 2.8029 12.3335 3.33333ZM3.00016 1.448V10.6667C2.53191 10.6663 2.0719 10.7898 1.66683 11.0247V3.33333C1.66689 2.91979 1.79514 2.51644 2.03392 2.1788C2.2727 1.84116 2.61027 1.58585 3.00016 1.448ZM10.3335 14.6667H3.00016C2.64654 14.6667 2.3074 14.5262 2.05735 14.2761C1.80731 14.0261 1.66683 13.687 1.66683 13.3333C1.66683 12.9797 1.80731 12.6406 2.05735 12.3905C2.3074 12.1405 2.64654 12 3.00016 12H12.3335V12.6667C12.3335 13.1971 12.1228 13.7058 11.7477 14.0809C11.3726 14.456 10.8639 14.6667 10.3335 14.6667Z"
      fill="currentColor"
    />
  </SvgIcon>
);
