import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const TeamIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35235 3.32373C7.71633 3.17296 8.10644 3.09537 8.5004 3.09537C8.89437 3.09537 9.28448 3.17296 9.64845 3.32373C10.0124 3.47449 10.3431 3.69547 10.6217 3.97405C10.9003 4.25262 11.1213 4.58334 11.272 4.94732C11.4228 5.31129 11.5004 5.7014 11.5004 6.09537C11.5004 6.48933 11.4228 6.87944 11.272 7.24342C11.1213 7.60739 10.9003 7.93811 10.6217 8.21669C10.3431 8.49526 10.0124 8.71624 9.64845 8.86701C9.28448 9.01777 8.89437 9.09537 8.5004 9.09537C8.10644 9.09537 7.71633 9.01777 7.35235 8.86701C6.98838 8.71624 6.65766 8.49526 6.37908 8.21669C6.10051 7.93811 5.87953 7.60739 5.72876 7.24342C5.578 6.87944 5.5004 6.48933 5.5004 6.09537C5.5004 5.7014 5.578 5.31129 5.72876 4.94732C5.87953 4.58334 6.10051 4.25262 6.37908 3.97405C6.65766 3.69547 6.98838 3.47449 7.35235 3.32373ZM8.5004 4.39537C8.27716 4.39537 8.05609 4.43934 7.84984 4.52477C7.64359 4.6102 7.45618 4.73543 7.29832 4.89329C7.14046 5.05115 7.01524 5.23855 6.92981 5.44481C6.84437 5.65106 6.8004 5.87212 6.8004 6.09537C6.8004 6.31861 6.84437 6.53968 6.92981 6.74593C7.01524 6.95218 7.14046 7.13959 7.29832 7.29745C7.45618 7.45531 7.64359 7.58053 7.84984 7.66596C8.05609 7.7514 8.27716 7.79537 8.5004 7.79537C8.72365 7.79537 8.94471 7.7514 9.15096 7.66596C9.35722 7.58053 9.54462 7.45531 9.70248 7.29745C9.86034 7.13959 9.98557 6.95218 10.071 6.74593C10.1564 6.53968 10.2004 6.31861 10.2004 6.09537C10.2004 5.87212 10.1564 5.65106 10.071 5.44481C9.98557 5.23855 9.86034 5.05115 9.70248 4.89329C9.54462 4.73543 9.35722 4.6102 9.15096 4.52477C8.94471 4.43934 8.72365 4.39537 8.5004 4.39537Z"
      fill="currentColor"
    />
    <path
      d="M8.50031 11.686C8.01441 11.686 7.53328 11.7817 7.08438 11.9677C6.63547 12.1536 6.22759 12.4262 5.88401 12.7697C5.54043 13.1133 5.26789 13.5212 5.08195 13.9701C4.89601 14.419 4.80031 15.9001 4.80031 16.386C4.80031 16.745 4.50929 17.036 4.15031 17.036C3.79132 17.036 3.50031 16.745 3.50031 16.386C3.50031 15.7294 3.62963 14.0792 3.88091 13.4726C4.13218 12.866 4.50048 12.3148 4.96477 11.8505C5.42906 11.3862 5.98026 11.0179 6.58689 10.7666C7.19352 10.5154 7.8437 10.386 8.50031 10.386C9.15691 10.386 9.80709 10.5154 10.4137 10.7666C11.0203 11.0179 11.5715 11.3862 12.0358 11.8505C12.5001 12.3148 12.8684 12.866 13.1197 13.4726C13.371 14.0792 13.5003 15.7294 13.5003 16.386C13.5003 16.745 13.2093 17.036 12.8503 17.036C12.4913 17.036 12.2003 16.745 12.2003 16.386C12.2003 15.9001 12.1046 14.419 11.9187 13.9701C11.7327 13.5212 11.4602 13.1133 11.1166 12.7697C10.773 12.4262 10.3651 12.1536 9.91623 11.9677C9.46733 11.7817 8.9862 11.686 8.50031 11.686Z"
      fill="currentColor"
    />
    <path
      d="M11.9078 2.35449C12.233 2.16678 12.6193 2.11592 12.9819 2.21308C13.3446 2.31024 13.6537 2.54748 13.8414 2.87259C14.0291 3.1977 14.08 3.58406 13.9828 3.94668C13.8857 4.3093 13.6484 4.61846 13.3233 4.80617C13.1552 4.90326 12.9706 4.96374 12.7814 4.98606C12.882 5.35647 12.9356 5.74617 12.9356 6.14841C12.9356 6.15766 12.9356 6.16692 12.9355 6.17617C13.2804 6.13366 13.6166 6.02249 13.9233 5.8454C14.5241 5.49857 14.9624 4.9273 15.1419 4.25726C15.3215 3.58723 15.2275 2.87332 14.8807 2.27259C14.5338 1.67186 13.9626 1.23351 13.2925 1.05397C12.6225 0.874436 11.9086 0.968424 11.3078 1.31526C10.9472 1.52349 10.645 1.81262 10.4223 2.15545C10.7865 2.33029 11.1231 2.55349 11.4237 2.81673C11.5447 2.62789 11.71 2.4687 11.9078 2.35449Z"
      fill="currentColor"
    />
    <path
      d="M6.58195 2.16197C6.23095 1.61821 5.68963 1.22245 5.06151 1.05415C4.39147 0.874614 3.67757 0.968602 3.07683 1.31544C2.4761 1.66227 2.03775 2.23354 1.85821 2.90357C1.67868 3.5736 1.77267 4.28751 2.1195 4.88825C2.46633 5.48898 3.0376 5.92733 3.70763 6.10687C3.83135 6.14001 3.95656 6.16384 4.08228 6.17847C4.08221 6.16846 4.08218 6.15843 4.08218 6.14841C4.08218 5.74693 4.13562 5.35793 4.23579 4.98815C4.16277 4.98043 4.09003 4.967 4.01822 4.94775C3.6556 4.85059 3.34643 4.61336 3.15873 4.28825C2.97103 3.96313 2.92016 3.57677 3.01732 3.21415C3.11449 2.85154 3.35172 2.54237 3.67683 2.35467C4.00194 2.16696 4.38831 2.1161 4.75092 2.21326C5.0962 2.30578 5.39302 2.52529 5.58266 2.82674C5.88247 2.56242 6.21834 2.33805 6.58195 2.16197Z"
      fill="currentColor"
    />
    <path
      d="M4.41851 7.84393C4.40718 7.84383 4.39585 7.84378 4.38452 7.84378C3.87975 7.84378 3.37993 7.9432 2.91358 8.13637C2.44724 8.32954 2.0235 8.61267 1.66658 8.96959C1.30965 9.32652 1.02653 9.75025 0.833359 10.2166C0.640193 10.6829 0.540771 11.1828 0.540771 11.6875C0.540771 12.0189 0.809401 12.2875 1.14077 12.2875C1.47214 12.2875 1.74077 12.0189 1.74077 11.6875C1.74077 11.3404 1.80915 10.9966 1.94201 10.6758C2.07488 10.3551 2.26961 10.0636 2.51511 9.81812C2.7606 9.57262 3.05205 9.37789 3.3728 9.24503C3.69356 9.11216 4.03734 9.04378 4.38452 9.04378C4.70225 9.04378 5.01714 9.10106 5.31413 9.21261C4.93648 8.81897 4.63087 8.35568 4.41851 7.84393Z"
      fill="currentColor"
    />
    <path
      d="M11.7137 9.20216C12.0025 9.09736 12.3076 9.0436 12.6155 9.0436C12.9626 9.0436 13.3064 9.11199 13.6272 9.24485C13.9479 9.37771 14.2394 9.57244 14.4849 9.81794C14.7304 10.0634 14.9251 10.3549 15.058 10.6756C15.1908 10.9964 15.2592 11.3402 15.2592 11.6874C15.2592 12.0187 15.5278 12.2874 15.8592 12.2874C16.1906 12.2874 16.4592 12.0187 16.4592 11.6874C16.4592 11.1826 16.3598 10.6828 16.1666 10.2164C15.9735 9.75007 15.6903 9.32634 15.3334 8.96941C14.9765 8.61249 14.5527 8.32936 14.0864 8.13619C13.62 7.94303 13.1202 7.8436 12.6155 7.8436C12.6101 7.8436 12.6048 7.84361 12.5994 7.84364C12.3889 8.35098 12.0868 8.81071 11.7137 9.20216Z"
      fill="currentColor"
    />
  </SvgIcon>
);
