import React, { FC } from "react";

import { BreadCrumbsIcon } from "components/icons/BreadCrumbsIcon";
import { Link } from "react-router-dom";
import { Box } from "ui/Box";
import { List } from "ui/List";
import { ListItem } from "ui/ListItem";
import { Typography } from "ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface BreadCrumbsProps {
  links: { id: number; title: string; route: string }[];
}

export const BreadCrumbs: FC<BreadCrumbsProps> = React.memo(({ links }) => {
  return (
    <Box sx={styles.root}>
      <BreadCrumbsIcon sx={styles.icon} />
      <List sx={styles.list}>
        {links.map(({ id, title, route }, index) => (
          <ListItem key={id} sx={styles.listItem}>
            {index !== links.length - 1 ? (
              <Link to={route}>
                <Typography sx={styles.linkTitle}>{title}</Typography>
              </Link>
            ) : (
              <Typography
                sx={{ ...styles.linkTitle, ...styles.title } as SxProps}
              >
                {title}
              </Typography>
            )}
            {index !== links.length - 1 && (
              <Typography sx={styles.textDivider}>/</Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
});
