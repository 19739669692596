import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const ArrowDropdownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4655_6171)">
      <path
        d="M12.6669 6.27905C12.6661 6.45394 12.5967 6.62154 12.4735 6.74572L9.41354 9.80572C9.03854 10.1803 8.53021 10.3906 8.0002 10.3906C7.4702 10.3906 6.96187 10.1803 6.58687 9.80572L5.05687 8.27572L3.52687 6.74572C3.4027 6.62081 3.33301 6.45184 3.33301 6.27572C3.33301 6.09959 3.4027 5.93063 3.52687 5.80572C3.58884 5.74323 3.66258 5.69364 3.74382 5.65979C3.82506 5.62594 3.91219 5.60852 4.0002 5.60852C4.08821 5.60852 4.17535 5.62594 4.25659 5.65979C4.33783 5.69364 4.41156 5.74323 4.47354 5.80572L6.0002 7.33572L7.52687 8.86572C7.58885 8.9282 7.66258 8.9778 7.74382 9.01165C7.82506 9.04549 7.9122 9.06292 8.0002 9.06292C8.08821 9.06292 8.17535 9.04549 8.25659 9.01165C8.33783 8.9778 8.41156 8.9282 8.47354 8.86572L11.5269 5.80572C11.5888 5.74323 11.6626 5.69364 11.7438 5.65979C11.8251 5.62594 11.9122 5.60852 12.0002 5.60852C12.0882 5.60852 12.1753 5.62594 12.2566 5.65979C12.3378 5.69364 12.4116 5.74323 12.4735 5.80572C12.5353 5.86801 12.5842 5.94189 12.6174 6.02311C12.6506 6.10434 12.6674 6.19131 12.6669 6.27905Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4655_6171">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
