import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    columnGap: 2,
    alignItems: "center",
  },
  icon: { m: 1 },
  list: {
    display: "flex",
  },
  listItem: {
    display: "flex",
    a: {
      color: basicTheme.palette.mainPalette.primaryBlack,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  linkTitle: {
    px: 2,
    py: 1,
    whiteSpace: "nowrap",
  },
  title: {
    px: 2,
    py: 1,
    cursor: "default",
  },
  textDivider: {
    opacity: "20%",
    mx: 1,
  },
};
