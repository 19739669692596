import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const DashboardIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3304_69)">
      <path
        d="M13.1667 0.667969H3.83333C2.9496 0.669027 2.10237 1.02056 1.47748 1.64545C0.852588 2.27034 0.501059 3.11757 0.5 4.0013L0.5 9.33464C0.501059 10.2184 0.852588 11.0656 1.47748 11.6905C2.10237 12.3154 2.9496 12.6669 3.83333 12.668H7.83333V14.0013H5.16667C4.98986 14.0013 4.82029 14.0715 4.69526 14.1966C4.57024 14.3216 4.5 14.4912 4.5 14.668C4.5 14.8448 4.57024 15.0143 4.69526 15.1394C4.82029 15.2644 4.98986 15.3346 5.16667 15.3346H11.8333C12.0101 15.3346 12.1797 15.2644 12.3047 15.1394C12.4298 15.0143 12.5 14.8448 12.5 14.668C12.5 14.4912 12.4298 14.3216 12.3047 14.1966C12.1797 14.0715 12.0101 14.0013 11.8333 14.0013H9.16667V12.668H13.1667C14.0504 12.6669 14.8976 12.3154 15.5225 11.6905C16.1474 11.0656 16.4989 10.2184 16.5 9.33464V4.0013C16.4989 3.11757 16.1474 2.27034 15.5225 1.64545C14.8976 1.02056 14.0504 0.669027 13.1667 0.667969ZM3.83333 2.0013H13.1667C13.6971 2.0013 14.2058 2.21202 14.5809 2.58709C14.956 2.96216 15.1667 3.47087 15.1667 4.0013V8.66797H1.83333V4.0013C1.83333 3.47087 2.04405 2.96216 2.41912 2.58709C2.79419 2.21202 3.3029 2.0013 3.83333 2.0013ZM13.1667 11.3346H3.83333C3.42116 11.3329 3.01958 11.2039 2.68353 10.9652C2.34749 10.7266 2.09339 10.3899 1.956 10.0013H15.044C14.9066 10.3899 14.6525 10.7266 14.3165 10.9652C13.9804 11.2039 13.5788 11.3329 13.1667 11.3346Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3304_69">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
