import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const HomeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.914 6.03537L10.8573 0.975001C10.2315 0.35062 9.38379 0 8.50001 0C7.61624 0 6.76851 0.35062 6.14268 0.975001L1.08601 6.03537C0.899625 6.22067 0.751855 6.44114 0.651263 6.68401C0.550672 6.92688 0.49926 7.18731 0.500008 7.45021V13.9988C0.500008 14.5296 0.710722 15.0386 1.08579 15.4139C1.46087 15.7892 1.96958 16 2.50001 16H14.5C15.0304 16 15.5392 15.7892 15.9142 15.4139C16.2893 15.0386 16.5 14.5296 16.5 13.9988V7.45021C16.5008 7.18731 16.4493 6.92688 16.3488 6.68401C16.2482 6.44114 16.1004 6.22067 15.914 6.03537ZM10.5 14.6659H6.50001V12.0416C6.50001 11.5109 6.71072 11.0019 7.0858 10.6266C7.46087 10.2513 7.96958 10.0404 8.50001 10.0404C9.03044 10.0404 9.53915 10.2513 9.91422 10.6266C10.2893 11.0019 10.5 11.5109 10.5 12.0416V14.6659ZM15.1667 13.9988C15.1667 14.1757 15.0964 14.3454 14.9714 14.4705C14.8464 14.5956 14.6768 14.6659 14.5 14.6659H11.8333V12.0416C11.8333 11.157 11.4822 10.3087 10.857 9.68319C10.2319 9.0577 9.38407 8.7063 8.50001 8.7063C7.61596 8.7063 6.76811 9.0577 6.14299 9.68319C5.51787 10.3087 5.16668 11.157 5.16668 12.0416V14.6659H2.50001C2.3232 14.6659 2.15363 14.5956 2.0286 14.4705C1.90358 14.3454 1.83334 14.1757 1.83334 13.9988V7.45021C1.83396 7.27343 1.90413 7.104 2.02868 6.9786L7.08534 1.92023C7.46114 1.54596 7.96979 1.33585 8.50001 1.33585C9.03023 1.33585 9.53888 1.54596 9.91468 1.92023L14.9713 6.9806C15.0954 7.1055 15.1655 7.27412 15.1667 7.45021V13.9988Z"
      fill="currentColor"
    />
  </SvgIcon>
);
