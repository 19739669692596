import { AIInboxIcon } from "components/icons/AIInboxIcon";
import { DashboardIcon } from "components/icons/DashboardIcon";
import { HomeIcon } from "components/icons/HomeIcon";
import { SalesJournalIcon } from "components/icons/SalesJournalIcon";
import { TeamIcon } from "components/icons/TeamIcon";

export const navigationMenuList = [
  { id: 1, icon: <HomeIcon />, route: "/Home", title: "Home" },
  { id: 2, icon: <TeamIcon />, route: "/Team", title: "Team" },
  { id: 3, icon: <AIInboxIcon />, route: "/AI-Inbox", title: "AI Inbox" },
  { id: 4, icon: <DashboardIcon />, route: "/Dashboard", title: "Dashboard" },
  {
    id: 5,
    icon: <SalesJournalIcon />,
    route: "/Sales-Journal",
    title: "Sales Journal",
  },
];
