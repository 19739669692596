import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DateFilter } from "components/DateFilter";
import { Box } from "ui/Box";

import { styles } from "./styles";

import { fromZonedTime } from "date-fns-tz";
import dateApi from "modules/Header/api/dateApi";
import { DisabledRoutes } from "modules/Header/constants";
import useDateStore from "../../store";

export interface FiltersProps {}

export const Filters: FC<FiltersProps> = React.memo(() => {
  const { setMinDate, setStartDate, setEndDate, startDate, endDate } =
    useDateStore((state) => state);
  const location = useLocation();
  const [cleanMinDate, setCleanMinDate] = useState<Date | undefined>();
  const [allPeriods, setAllPeriods] = useState(
    sessionStorage.getItem("allPeriods")
  );
  const currentDate = new Date();
  const currentDateUTC = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    )
  );

  const firstDayOfMonthUTC = new Date(
    Date.UTC(currentDateUTC.getUTCFullYear(), currentDateUTC.getUTCMonth(), 1)
  );
  const currentDateClearUTC = new Date(
    Date.UTC(
      currentDateUTC.getUTCFullYear(),
      currentDateUTC.getUTCMonth(),
      currentDateUTC.getUTCDate()
    )
  );

  const dateObject =
    startDate && endDate
      ? {
          startDate: fromZonedTime(new Date(startDate * 1000), "UTC"),
          endDate: fromZonedTime(new Date(endDate * 1000), "UTC"),
        }
      : typeof allPeriods === "string" && JSON.parse(allPeriods) === true
      ? {}
      : {
          startDate: new Date(
            fromZonedTime(firstDayOfMonthUTC.toISOString(), "UTC")
          ),
          endDate: currentDateClearUTC,
        };

  const [selectedDatedRange, setSelectedDateRange] = useState<
    { startDate: Date; endDate: Date } | {}
  >(dateObject);

  useEffect(() => {
    const storedStartDate = sessionStorage.getItem("start_date_filter");
    const storedEndDate = sessionStorage.getItem("end_date_filter");

    if (storedStartDate && storedEndDate) {
      setSelectedDateRange({
        startDate: new Date(parseInt(storedStartDate) * 1000),
        endDate: new Date(parseInt(storedEndDate) * 1000),
      });
    } else {
      setSelectedDateRange(dateObject);
    }
  }, []); // Empty dependency array ensures this effect runs only once, on component mount

  useEffect(() => {
    if (
      typeof selectedDatedRange === "object" &&
      "startDate" in selectedDatedRange &&
      "endDate" in selectedDatedRange
    ) {
      // Convert selected dates to UTC before getting the timestamp
      const startDateUTC = new Date(
        Date.UTC(
          selectedDatedRange.startDate.getFullYear(),
          selectedDatedRange.startDate.getMonth(),
          selectedDatedRange.startDate.getDate(),
          selectedDatedRange.startDate.getHours(),
          selectedDatedRange.startDate.getMinutes(),
          selectedDatedRange.startDate.getSeconds()
        )
      );

      const endDateUTC = new Date(
        Date.UTC(
          selectedDatedRange.endDate.getFullYear(),
          selectedDatedRange.endDate.getMonth(),
          selectedDatedRange.endDate.getDate(),
          selectedDatedRange.endDate.getHours(),
          selectedDatedRange.endDate.getMinutes(),
          selectedDatedRange.endDate.getSeconds()
        )
      );

      const timestampStartDate = Math.floor(startDateUTC.getTime() / 1000);
      const timestampEndDate = Math.floor(endDateUTC.getTime() / 1000);

      setStartDate(timestampStartDate);
      setEndDate(timestampEndDate);

      sessionStorage.setItem(
        "start_date_filter",
        timestampStartDate.toString()
      );
      sessionStorage.setItem("end_date_filter", timestampEndDate.toString());
    } else {
      setStartDate(0);
      setEndDate(0);
      sessionStorage.removeItem("start_date_filter");
      sessionStorage.removeItem("end_date_filter");
    }
  }, [selectedDatedRange, setStartDate, setEndDate]);

  const getMinimumDate = useCallback(async () => {
    try {
      const res = await dateApi.getMinimumDate();
      setMinDate(res.start_date);
      setCleanMinDate(new Date(res.start_date * 1000));
    } catch (error: unknown) {
      console.error(error);
    }
  }, [setMinDate]);

  useEffect(() => {
    getMinimumDate();
  }, [getMinimumDate]);

  return (
    <Box sx={styles.root}>
      <DateFilter
        setSelectedDateRange={setSelectedDateRange}
        selectedDateRange={selectedDatedRange}
        minDate={cleanMinDate}
        disabled={DisabledRoutes.some((route) =>
          location.pathname.includes(route)
        )}
        maxDate={new Date()}
      />
    </Box>
  );
});
