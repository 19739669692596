import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    px: 2,
    py: 2.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "max-content",
    columnGap: 2,
    cursor: "pointer",
  },
  rootDisabled: {
    pointerEvents: "none",
  },
  iconButton: {
    width: 16,
    height: 16,
  },
  iconButtonOpen: {
    transform: "rotate(180deg)",
    transition: ".3s ease-in-out",
  },
  arrowIcon: {
    color: basicTheme.palette.mainPalette.placeholderText,
  },
  gradientTitle: {
    background: basicTheme.palette.gradientsPalette.gradient4,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
};
