import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  small: {
    "&": {
      width: "12px",
      height: "12px",
    },
  },
  medium: {
    "&": {
      width: "16px",
      height: "16px",
    },
  },
  mediumSecondary: {
    "&": {
      width: "24px",
      height: "24px",
    },
  },
  mediumBig: {
    "&": {
      width: "28px",
      height: "28px",
    },
  },
  large: {
    "&": {
      width: "50px",
      height: "50px",
    },
  },
  huge: {
    width: 120,
    height: 120,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 162,
      height: 162,
    },
  },
};
