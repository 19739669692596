import { create } from "zustand";

export interface UserState {
  userId: string;
  setUserId: (userId: string) => void;
  userEmail: string;
  setUserEmail: (userEmail: string) => void;
  userName: string;
  setUserName: (userName: string) => void;
  userSurname: string;
  setUserSurname: (userSurname: string) => void;
}

const useUserInfoStore = create<UserState>()((set) => ({
  userId: "",
  setUserId: (userId: string) => set((state) => ({ userId })),
  userEmail: "",
  setUserEmail: (userEmail: string) => set((state) => ({ userEmail })),
  userName: "",
  setUserName: (userName: string) => set((state) => ({ userName })),
  userSurname: "",
  setUserSurname: (userSurname: string) => set((state) => ({ userSurname })),
}));

export default useUserInfoStore;
