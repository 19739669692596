import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    display: "flex",
    width: "100vw",
  },
  mainContent: {
    width: "100%",
    height: "100vh",
    bgcolor: basicTheme.palette.mainPalette.primaryGrey,
    maxHeight: "100vh",
    overflow: "hidden",
  },
  routeContainer: {
    width: "100%",
    overflowY: "auto",
    minHeight: "calc(100vh - 64px)",
    maxHeight: "calc(100vh - 64px)",
    height: "calc(100vh - 64px)",
  },
  loginContainer: {
    width: "100%",
    overflowY: "auto",
    minHeight: "100%",
    maxHeight: "100%",
    height: "100%",
  },
};
