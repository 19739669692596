import { create } from "zustand";

export interface DateState {
  minDate: number;
  startDate: number;
  endDate: number;
  setMinDate: (date: number) => void;
  setStartDate: (date: number) => void;
  setEndDate: (date: number) => void;
}

const useDateStore = create<DateState>()((set) => ({
  minDate: 0,
  startDate: sessionStorage.getItem("start_date_filter")
    ? Number(sessionStorage.getItem("start_date_filter"))
    : 0,
  endDate: sessionStorage.getItem("end_date_filter")
    ? Number(sessionStorage.getItem("end_date_filter"))
    : 0,
  setMinDate: (date: number) => set((state) => ({ minDate: date })),
  setStartDate: (date: number) => set((state) => ({ startDate: date })),
  setEndDate: (date: number) => set((state) => ({ endDate: date })),
}));

export default useDateStore;
