import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    position: "sticky",
    height: 65,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    bgcolor: basicTheme.palette.mainPalette.white,
    p: 5,
    ml: "auto",
    zIndex: 10,
    borderBottom: `1px solid ${basicTheme.palette.mainPalette.lightSecondaryGrey}`,
    width: "calc(100% - 65px)",
    [basicTheme.breakpoints.up("laptop")]: {
      width: "100%",
    },
  },
};
