import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

interface AppLogoProps extends SvgIconProps {
  short?: boolean;
}

export const AppLogo: FC<AppLogoProps> = ({
  short = false,
  ...rest
}) => (
  <>
    {short ? (
      <SvgIcon
        {...rest}
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.05556 18.9585C1.79119 18.9585 0 16.2809 0 12.9981C0 9.705 2.42337 6.62734 3.65613 6.62734C4.88889 6.62734 5.7002 7.97125 5.7002 12.9981C5.7002 16.5785 4.29885 18.9585 3.05556 18.9585Z"
          fill="black"
        />
        <path
          d="M16.2682 11.5311C16.2682 16.322 12.0853 18.9893 10.3362 18.9893C8.62932 18.9893 7.18583 17.0093 7.00671 9.68449C6.89081 4.39091 9.06131 0.0411413 10.8104 0.000105824C12.5805 -0.0306708 16.2682 6.65812 16.2682 11.5311Z"
          fill="black"
        />
      </SvgIcon>
    ) : (
      <SvgIcon
        {...rest}
        viewBox="0 0 122 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.55556 18.9585C2.29119 18.9585 0.5 16.2809 0.5 12.9981C0.5 9.705 2.92337 6.62734 4.15613 6.62734C5.38889 6.62734 6.2002 7.97125 6.2002 12.9981C6.2002 16.5785 4.79885 18.9585 3.55556 18.9585Z"
          fill="black"
        />
        <path
          d="M16.7682 11.5311C16.7682 16.322 12.5853 18.9893 10.8362 18.9893C9.12932 18.9893 7.68583 17.0093 7.50671 9.68449C7.39081 4.39091 9.56131 0.0411413 11.3104 0.000105824C13.0805 -0.0306708 16.7682 6.65812 16.7682 11.5311Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.15 8.78169H119.962L117.675 4.29856H116.78L114.493 8.78169H115.294L116.095 7.22234H118.35L119.15 8.78169ZM118.012 6.56577H116.421L117.212 5.01668L118.012 6.56577Z"
          fill="black"
        />
        <path d="M121.5 4.29856H120.752V8.78169H121.5V4.29856Z" fill="black" />
        <path
          d="M107.023 17.4094H108.772V13.2443C108.772 11.5003 110.131 10.7309 111.965 10.7309C112.354 10.7309 112.944 10.8027 113.303 10.9053L112.987 9.02789C112.765 9.00737 112.302 8.98686 112.059 8.98686C110.679 8.98686 109.52 9.54084 108.772 10.5052V9.12022H107.023V17.4094Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.7902 13.2648C94.7902 15.9732 96.8343 17.5428 100.111 17.5428C102.124 17.5428 103.873 16.8554 104.737 15.6243L103.314 14.6498C102.745 15.5115 101.46 15.9732 100.101 15.9732C98.1724 15.9732 96.75 15.2448 96.5709 13.6752H104.947V13.2751C104.947 10.5667 102.724 8.99712 99.8688 8.99712C96.8448 8.99712 94.7902 10.5667 94.7902 13.2648ZM96.6868 12.2902C97.0977 11.0386 98.2778 10.5872 99.8793 10.5872C101.439 10.5872 102.693 11.1002 102.988 12.2902H96.6868Z"
          fill="black"
        />
        <path
          d="M89.5747 10.8129V14.8036C89.5747 16.6605 90.2491 17.512 92.0613 17.512C92.5776 17.512 93.1255 17.4197 93.3994 17.3581L93.6523 15.809C93.1782 15.9013 92.7673 15.9629 92.2299 15.9629C91.6188 15.9629 91.3027 15.6141 91.3027 14.9883V10.8232H93.842V9.30488H91.3027V7.32492H89.5642V9.30488H87.773V10.8129H89.5747Z"
          fill="black"
        />
        <path
          d="M77.1418 15.8398C78.1849 16.9375 80.2184 17.5633 82.3467 17.5633C85.2021 17.5633 87.0354 16.64 87.0354 14.9883C87.0354 13.1622 85.1494 12.6698 82.842 12.4544L82.7374 12.4458C80.4311 12.2572 79.4492 12.1769 79.4492 11.4592C79.4492 10.8232 80.3975 10.5565 81.8515 10.5565C83.1897 10.5565 84.7069 10.9053 85.4023 11.6336L86.6983 10.5359C85.6762 9.52032 83.8008 8.98686 82.0728 8.98686C79.3755 8.98686 77.658 9.91016 77.658 11.5413C77.658 13.3264 79.7864 13.8188 81.9147 13.9727C84.2011 14.1266 85.1494 14.2189 85.1494 15.0088C85.1494 15.7167 84.1695 16.045 82.41 16.045C80.8295 16.045 79.1437 15.5423 78.3745 14.6805L77.1418 15.8398Z"
          fill="black"
        />
        <path
          d="M73.4013 17.4094H75.1925V9.12022H73.4013V17.4094Z"
          fill="black"
        />
        <path
          d="M73.3592 7.51984H75.2347V5.77583H73.3592V7.51984Z"
          fill="black"
        />
        <path
          d="M58.8822 15.5115C60.0096 16.7426 62.2854 17.5633 65.2462 17.5633C68.2385 17.5633 71.0622 16.5887 71.0622 14.1266C71.0622 11.9209 69.0498 11.1925 65.4885 10.9258C62.2011 10.6796 61.3161 10.4231 61.3161 9.38695C61.3161 8.50469 62.4961 7.97123 64.7404 7.97123C66.5948 7.97123 68.4387 8.54572 69.3343 9.56135L70.7672 8.39184C69.5872 7.06845 67.3956 6.31955 64.7404 6.31955C61.7481 6.31955 59.3774 7.57113 59.3774 9.4998C59.3774 11.3977 60.9578 12.28 65.0144 12.5877C67.8908 12.8134 69.1868 13.0904 69.1868 14.2189C69.1868 15.4499 67.3745 15.8706 65.3304 15.8706C63.2021 15.8706 61.1791 15.3166 60.2519 14.3215L58.8822 15.5115Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8668 18.9277C42.8668 20.3845 44.953 21 47.8505 21C51.2538 21 53.0661 20.0254 53.0661 18.4455C53.0661 16.9888 51.749 16.4656 48.2193 16.4143C45.4798 16.3938 44.6896 16.3527 44.6896 15.7269C44.6896 15.3063 45.0795 15.1114 45.6168 15.1114H45.68C46.2279 15.2653 46.9444 15.3576 47.5661 15.3576C50.1896 15.3576 51.9387 14.1881 51.9387 12.1774C51.9387 11.6747 51.7806 11.2233 51.5594 10.8129C51.6963 10.3923 52.0756 10.2384 52.5287 10.2384C52.8027 10.2384 53.0977 10.3 53.3716 10.3718L53.0977 8.78168C53.022 8.77498 52.9452 8.7661 52.8681 8.75717C52.7088 8.73875 52.5479 8.72013 52.3917 8.72013C51.5067 8.72013 50.9166 9.14074 50.6216 9.79731C49.8525 9.29462 48.7883 8.98686 47.5239 8.98686C44.9425 8.98686 43.1724 10.1769 43.1724 12.1671C43.1724 13.1109 43.5517 13.8701 44.2576 14.4138C43.3093 14.6805 42.9933 15.2961 42.9933 15.9321C42.9933 16.5271 43.3726 17.0401 43.9415 17.3171C43.1408 17.6659 42.8668 18.2404 42.8668 18.9277ZM44.953 12.1774C44.953 11.0079 46.0172 10.4539 47.5345 10.4539C49.0938 10.4539 50.158 11.0079 50.158 12.1774C50.158 13.3674 49.0938 13.9214 47.5766 13.9214C46.0172 13.9214 44.953 13.3674 44.953 12.1774ZM44.5843 18.5584C44.5843 18.1173 44.9425 17.789 45.4904 17.6761L47.1235 17.6966C49.9157 17.7377 51.1379 17.789 51.1379 18.5584C51.1379 19.3278 49.6839 19.5638 47.8927 19.5638C45.8065 19.5638 44.5843 19.2355 44.5843 18.5584Z"
          fill="black"
        />
        <path
          d="M38.8314 17.4094H40.6226V9.12022H38.8314V17.4094Z"
          fill="black"
        />
        <path
          d="M38.7892 7.51984H40.6647V5.77583H38.7892V7.51984Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.134 17.4094H32.7519C34.9013 17.4094 36.5134 16.045 36.5134 14.0547C36.5134 12.8442 35.9023 11.6234 34.522 11.1617C35.2911 10.7206 35.6494 9.93067 35.6494 9.20229C35.6494 7.49932 34.248 6.46317 32.3199 6.46317H25.134V17.4094ZM26.9568 8.11485H32.0986C33.0258 8.11485 33.816 8.46365 33.816 9.34592C33.816 10.2487 33.0258 10.5975 32.0986 10.5975H26.9568V8.11485ZM26.9568 15.7475V12.1774H32.7519C33.8371 12.1774 34.6379 12.8339 34.6379 13.9419C34.6379 15.0909 33.8266 15.7475 32.7519 15.7475H26.9568Z"
          fill="black"
        />
      </SvgIcon>
    )}
  </>
);
