import { FC } from "react";

import { Box } from "ui/Box";
import { Typography } from "ui/Typography";

import { styles } from "./styles";

import { clearDate } from "helpers";

export interface DateInfoTitleProps {
  selectedDateRange: { startDate: Date; endDate: Date } | {};
}

export const DateInfoTitle: FC<DateInfoTitleProps> = ({
  selectedDateRange,
}) => {
  return (
    <Box
      sx={
        "startDate" in selectedDateRange && "endDate" in selectedDateRange
          ? styles.titleBoxActive
          : styles.titleBox
      }
    >
      <Typography
        variant="buttonTextSmall"
        sx={
          "startDate" in selectedDateRange && "endDate" in selectedDateRange
            ? styles.dateTitleActive
            : styles.dateTitle
        }
      >
        {selectedDateRange &&
        "startDate" in selectedDateRange &&
        "endDate" in selectedDateRange
          ? `${clearDate(selectedDateRange.startDate)} - ${clearDate(
              selectedDateRange.endDate
            )}`
          : "MM, DD, YYYY — MM, DD, YYYY"}
      </Typography>
    </Box>
  );
};
