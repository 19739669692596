import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  root: {
    p: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "min-content",
    borderRadius: 2,
    position: "relative",
  },
  rootDisabled: {
    pointerEvents: "none",
  },
  dropDown: {
    position: "absolute",
    top: 46,
    right: -20,
    zIndex: 1000,
    bgcolor: basicTheme.palette.mainPalette.white,
    width: "100vw",
    maxWidth: 384,
    height: "auto",
    borderRadius: 6,
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.16)",
    background: basicTheme.palette.mainPalette.white,
    padding: 6,
    display: "flex",
    flexDirection: "column",
    [basicTheme.breakpoints.up("tablet")]: {
      right: 0,
      flexDirection: "row",
      maxWidth: 532,
    },
  },
  titleBox: {
    width: "100%",
    px: 2,
    py: 1,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    border: `1px solid ${basicTheme.palette.mainPalette.placeholderText}`,
    position: "relative",
    mb: 5,
  },
  dateTitle: {
    color: basicTheme.palette.mainPalette.placeholderText,
  },
  titleBoxActive: {
    width: "100%",
    px: 2,
    py: 1,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    position: "relative",
    mb: 5,
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      borderRadius: 2,
      background: basicTheme.palette.gradientsPalette.gradient5,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      left: "2px",
      top: "2px",
      transform: "translate(-1px, -1px)",
      width: "calc(100% - 2px)",
      height: "calc(100% - 2px)",
      borderRadius: 2,
      background: basicTheme.palette.mainPalette.white,
    },
  },
  dateTitleActive: {
    background: basicTheme.palette.gradientsPalette.gradient5,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    zIndex: 3,
    position: "relative",
  },
  buttonBox: {
    mt: 6,
    display: "flex",
    columnGap: 2,
    alignItems: "center",
    justifyContent: "end",
    width: "100%",
    [basicTheme.breakpoints.up("tablet")]: {
      width: 336,
      ml: "auto",
    },
  },
  divider: {
    width: "100%",
    my: 6,
    color: basicTheme.palette.mainPalette.secondaryGrey,
    height: 1,
    [basicTheme.breakpoints.up("tablet")]: {
      height: "auto",
      width: "1px",
      my: 0,
      mx: 5,
    },
  },
  defaultPeriods: {
    [basicTheme.breakpoints.up("tablet")]: {
      maxWidth: 115,
    },
  },
  datePicker: {
    width: "100%",
  },
  dateInfoTitle: {
    width: "100%",
    mt: 31,
    [basicTheme.breakpoints.up("tablet")]: {
      width: 336,
      ml: "auto",
      mt: 0,
    },
  },
};
