import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const ArrowsLeftIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.48657 13.1659C7.31168 13.1652 7.14408 13.0957 7.01991 12.9726L3.95991 9.91256C3.58537 9.53756 3.375 9.02923 3.375 8.49923C3.375 7.96922 3.58537 7.46089 3.95991 7.08589L7.01991 4.02589C7.14482 3.90173 7.31378 3.83203 7.48991 3.83203C7.66603 3.83203 7.835 3.90173 7.95991 4.02589C8.02239 4.08787 8.07199 4.1616 8.10584 4.24284C8.13968 4.32408 8.15711 4.41122 8.15711 4.49923C8.15711 4.58723 8.13968 4.67437 8.10584 4.75561C8.07199 4.83685 8.02239 4.91058 7.95991 4.97256L4.89991 8.02589C4.83742 8.08787 4.78783 8.1616 4.75398 8.24284C4.72013 8.32408 4.70271 8.41122 4.70271 8.49923C4.70271 8.58723 4.72013 8.67437 4.75398 8.75561C4.78783 8.83685 4.83742 8.91058 4.89991 8.97256L7.95991 12.0259C8.02239 12.0879 8.07199 12.1616 8.10584 12.2428C8.13968 12.3241 8.15711 12.4112 8.15711 12.4992C8.15711 12.5872 8.13968 12.6744 8.10584 12.7556C8.07199 12.8369 8.02239 12.9106 7.95991 12.9726C7.89761 13.0343 7.82374 13.0832 7.74251 13.1164C7.66129 13.1496 7.57431 13.1664 7.48657 13.1659Z"
      fill="currentColor"
    />
    <path
      d="M12.1534 13.1659C11.9785 13.1652 11.8109 13.0957 11.6867 12.9726L7.6867 8.97256C7.62421 8.91058 7.57462 8.83685 7.54077 8.75561C7.50693 8.67437 7.4895 8.58723 7.4895 8.49923C7.4895 8.41122 7.50693 8.32408 7.54077 8.24284C7.57462 8.1616 7.62421 8.08787 7.6867 8.02589L11.6867 4.02589C11.8116 3.90173 11.9806 3.83203 12.1567 3.83203C12.3328 3.83203 12.5018 3.90173 12.6267 4.02589C12.6892 4.08787 12.7388 4.1616 12.7726 4.24284C12.8065 4.32408 12.8239 4.41122 12.8239 4.49923C12.8239 4.58723 12.8065 4.67437 12.7726 4.75561C12.7388 4.83685 12.6892 4.91058 12.6267 4.97256L9.10003 8.49923L12.6267 12.0259C12.6892 12.0879 12.7388 12.1616 12.7726 12.2428C12.8065 12.3241 12.8239 12.4112 12.8239 12.4992C12.8239 12.5872 12.8065 12.6744 12.7726 12.7556C12.7388 12.8369 12.6892 12.9106 12.6267 12.9726C12.5644 13.0343 12.4905 13.0832 12.4093 13.1164C12.3281 13.1496 12.2411 13.1664 12.1534 13.1659Z"
      fill="currentColor"
    />
  </SvgIcon>
);
