import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { styles } from "AppStyles";
import { theme } from "theme";

import Routing from "Routing";
import { Header } from "modules/Header";
import { NavigationMenu, useUserInfoStore } from "modules/NavigationDrawer";
import { Box } from "ui/Box";

import Intercom from "@intercom/messenger-js-sdk";
import { intercomAppId } from "utils";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const accessTokenStorage = localStorage.getItem("access_token");
  const refreshTokenStorage = localStorage.getItem("refresh_token");

  // TODO: remove this log
  console.log(accessTokenStorage);

  const { userId, userEmail, userName, userSurname } = useUserInfoStore(
    (state) => state
  );
  useEffect(() => {
    if (
      intercomAppId &&
      (accessTokenStorage || refreshTokenStorage) &&
      !location.pathname.includes("/login") &&
      !location.pathname.includes("/signup")
    ) {
      Intercom({
        app_id: intercomAppId,
        user_id: userId,
        name: `${userName} ${userSurname}`,
        email: userEmail,
      });
    }
  }, [
    location,
    accessTokenStorage,
    refreshTokenStorage,
    userId,
    userEmail,
    userName,
    userSurname,
  ]);

  useEffect(() => {
    if (
      (!accessTokenStorage || !refreshTokenStorage) &&
      !location.pathname.includes("/login") &&
      !location.pathname.includes("/signup")
    ) {
      navigate("/login");
    }
  }, [accessTokenStorage, refreshTokenStorage, location, navigate]);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={styles.root}>
          {!location.pathname.includes("/login") &&
            !location.pathname.includes("/signup") && <NavigationMenu />}
          <Box sx={styles.mainContent}>
            {!location.pathname.includes("/login") &&
              !location.pathname.includes("/signup") && <Header />}
            <Box
              sx={
                !location.pathname.includes("/login") &&
                !location.pathname.includes("/signup")
                  ? styles.routeContainer
                  : styles.loginContainer
              }
            >
              <Routing />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default App;
