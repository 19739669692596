import React, { FC, ReactNode } from "react";

import { List } from "ui/List";
import { ListItem } from "ui/ListItem";
import { NavigationListItem } from "../NavigationListItem";

import { styles } from "./styles";

import { useLocation } from "react-router-dom";

import { useBreakpoints } from "hooks/useBreakpoints";

export interface NavigationListProps {
  navigationList: {
    id: number;
    icon: ReactNode;
    route: string;
    title: string;
  }[];
  long: boolean;
  setLong: (value: boolean) => void;
}

export const NavigationList: FC<NavigationListProps> = React.memo(
  ({ navigationList, long, setLong }) => {
    const location = useLocation();
    const pathname = location.pathname.replace(/[-/]/g, " ");
    const { isUpLaptop } = useBreakpoints();

    return (
      <List sx={styles.root}>
        {navigationList.map((item) => (
          <ListItem key={item.id} onClick={() => !isUpLaptop && setLong(false)} sx={styles.listItem}>
            <NavigationListItem
              {...item}
              active={pathname.includes(item.title) ? true : false}
              long={long}
            />
          </ListItem>
        ))}
      </List>
    );
  }
);
