import React, { FC, useCallback, useEffect, useState } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AppLogo } from "app/AppLogo";
import { ArrowsLeftIcon } from "components/icons/ArrowsLeftIcon";
import { navigationMenuList } from "modules/NavigationDrawer/constants/header";
import { Box } from "ui/Box";
import { IconButton } from "ui/IconButton";
import { NavigationList } from "../NavigationList";
import { NavigationSalesButton } from "../NavigationSalesButton";
import { UserProfileButton } from "../UserProfileButton";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

import { useBreakpoints } from "hooks/useBreakpoints";
import { useNavigate } from "react-router-dom";

import globalApiRequests from "api";
import useUserInfoStore from "modules/NavigationDrawer/store";

export interface NavigationMenuProps {}

export const NavigationMenu: FC<NavigationMenuProps> = React.memo(() => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [userImage, setUserImage] = useState<string>("");
  const { setUserId, setUserEmail, setUserName, setUserSurname } =
    useUserInfoStore((state) => state);
  const { isUpLaptop } = useBreakpoints();
  const navigate = useNavigate();

  const getAccountInfo = useCallback(async () => {
    try {
      const res = await globalApiRequests.accountInfoApi.getAccountInfo();
      setUserId(res.id.toString());
      setUserEmail(res.email);
      setUserName(res.first_name);
      setUserSurname(res.last_name);
      setName(res.first_name);
      setSurname(res.last_name);
      setCompanyName(res.company);
      setUserImage(res.image);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getAccountInfo();
  }, [getAccountInfo]);

  const contentContainerStyles = (open: boolean): SxProps =>
    open
      ? styles.contentContainer
      : ({
          ...styles.contentContainer,
          ...styles.shortContentContainer,
        } as SxProps);

  const headNavigationStyles = (open: boolean): SxProps =>
    open
      ? styles.headNavigation
      : ({
          ...styles.headNavigation,
          ...styles.shortHeadNavigation,
        } as SxProps);

  return (
    <Box sx={styles.rootContainer}>
      {!isUpLaptop && open && <Box sx={styles.blackBackground}></Box>}
      <ClickAwayListener onClickAway={() => !isUpLaptop && setOpen(false)}>
        <Box
          sx={
            open
              ? styles.root
              : ({ ...styles.root, ...styles.shortRoot } as SxProps)
          }
        >
          <Box sx={headNavigationStyles(open)}>
            <AppLogo
              short={!open}
              sx={open ? styles.fullLogo : styles.logo}
              onClick={() => {
                navigate("/Home");
                !isUpLaptop && setOpen(false);
              }}
            />
            <IconButton
              onClick={() => setOpen(!open)}
              sx={open ? styles.openIcon : styles.icon}
              disableRipple
            >
              <ArrowsLeftIcon />
            </IconButton>
          </Box>
          {isUpLaptop ? (
            <>
              <Box sx={contentContainerStyles(open)}>
                <Box>
                  <NavigationList
                    navigationList={navigationMenuList}
                    long={open}
                    setLong={setOpen}
                  />
                </Box>
                {/* TODO: Return it later */}
                {/* <Box sx={styles.navigationButton}>
                  <NavigationSalesButton long={open} />
                </Box> */}
              </Box>
              <Box
                sx={styles.navigationButton}
                onClick={() => !isUpLaptop && setOpen(false)}
              >
                <UserProfileButton
                  long={open}
                  name={name}
                  surname={surname}
                  companyName={companyName}
                  image={userImage}
                />
              </Box>
            </>
          ) : (
            open && (
              <>
                <Box sx={contentContainerStyles(open)}>
                  <Box>
                    <NavigationList
                      navigationList={navigationMenuList}
                      long={open}
                      setLong={setOpen}
                    />
                  </Box>
                  {/* TODO: Return it later */}
                  {/* <Box
                    sx={styles.navigationButton}
                    onClick={() => !isUpLaptop && setOpen(false)}
                  >
                    <NavigationSalesButton long={open} />
                  </Box> */}
                </Box>
                <Box
                  sx={styles.navigationButton}
                  onClick={() => !isUpLaptop && setOpen(false)}
                >
                  <UserProfileButton
                    long={open}
                    name={name}
                    surname={surname}
                    companyName={companyName}
                    image={userImage}
                  />
                </Box>
              </>
            )
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
});
