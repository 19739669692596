import React, { useState } from "react";

import { Box } from "ui/Box";

import { styles } from "./styles";

import { addMonths, endOfMonth, isSameDay, startOfMonth } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface DatePickerProps {
  onChange: (selection: { startDate: Date; endDate: Date }) => void;
  disabledDates?: Date[];
  months?: number;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  staticRanges?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  disabledDates,
  months = 2,
  minDate = new Date(),
  maxDate,
  startDate = new Date(),
  endDate = new Date(),
  staticRanges = true,
}) => {
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  const currentDate = new Date();

  const currentDateUTC = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate(),
      currentDate.getUTCHours(), // Include hours, minutes, and seconds for precision
      currentDate.getUTCMinutes(),
      currentDate.getUTCSeconds()
    )
  );

  const currentDateClearUTC = new Date(
    Date.UTC(
      currentDateUTC.getUTCFullYear(),
      currentDateUTC.getUTCMonth(),
      currentDateUTC.getUTCDate(),
      currentDateUTC.getUTCHours(), // Include hours, minutes, and seconds for precision
      currentDateUTC.getUTCMinutes(),
      currentDateUTC.getUTCSeconds()
    )
  );

  const lastDayOfPreviousMonth = new Date(
    Date.UTC(
      currentDateClearUTC.getUTCFullYear(),
      currentDateClearUTC.getUTCMonth(),
      0,
      0,
      0,
      0
    )
  );

  return (
    <Box sx={styles.root}>
      <DateRangePicker
        onChange={handleOnChange}
        moveRangeOnFirstSelection={false}
        months={months}
        ranges={state}
        direction="horizontal"
        showMonthAndYearPickers={false}
        showDateDisplay={false}
        showPreview={false}
        editableDateInputs={false}
        minDate={minDate}
        maxDate={maxDate}
        disabledDates={disabledDates}
        inputRanges={[]}
        monthDisplayFormat="MMMM yyyy"
        className="date-picker"
        weekStartsOn={1}
        staticRanges={
          !staticRanges
            ? []
            : [
                {
                  label: "This month",
                  range: () => ({
                    startDate: startOfMonth(currentDateClearUTC),
                    endDate: currentDateClearUTC, // Set the end of the current UTC month
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(
                        range.startDate as Date,
                        definedRange.startDate as Date
                      ) &&
                      isSameDay(
                        range.endDate as Date,
                        definedRange.endDate as Date
                      )
                    );
                  },
                },
                {
                  label: "Last month",
                  range: () => ({
                    startDate: startOfMonth(addMonths(currentDateClearUTC, -1)),
                    endDate: lastDayOfPreviousMonth,
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(
                        range.startDate as Date,
                        definedRange.startDate as Date
                      ) &&
                      isSameDay(
                        range.endDate as Date,
                        definedRange.endDate as Date
                      )
                    );
                  },
                },
                {
                  label: "Last 3 month",
                  range: () => ({
                    startDate: startOfMonth(addMonths(currentDateClearUTC, -3)),
                    endDate: lastDayOfPreviousMonth,
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(
                        range.startDate as Date,
                        definedRange.startDate as Date
                      ) &&
                      isSameDay(
                        range.endDate as Date,
                        definedRange.endDate as Date
                      )
                    );
                  },
                },
                {
                  label: "Last 6 month",
                  range: () => ({
                    startDate: startOfMonth(addMonths(currentDateClearUTC, -6)),
                    endDate: lastDayOfPreviousMonth,
                  }),
                  isSelected(range) {
                    const definedRange = this.range();
                    return (
                      isSameDay(
                        range.startDate as Date,
                        definedRange.startDate as Date
                      ) &&
                      isSameDay(
                        range.endDate as Date,
                        definedRange.endDate as Date
                      )
                    );
                  },
                },
              ]
        }
      />
    </Box>
  );
};

export default DatePicker;
