import { FC, ReactNode } from "react";

import { ArrowDropdownIcon } from "components/icons/ArrowDropdownIcon";
import { Box } from "ui/Box";
import { IconButton } from "ui/IconButton";
import { Typography } from "ui/Typography";

import { SxProps } from "@mui/material";
import { styles } from "./styles";

export interface DropDownFilterProps {
  title: string | ReactNode;
  gradientTitle?: boolean;
  openFilter: boolean;
  setOpenFilter: (openFilter: boolean) => void;
  disabled?: boolean;
}

export const DropDownFilter: FC<DropDownFilterProps> = ({
  title,
  gradientTitle = true,
  openFilter,
  setOpenFilter,
  disabled = false,
}) => {
  const buttonStyles = (open: boolean): SxProps =>
    open
      ? ({ ...styles.iconButton, ...styles.iconButtonOpen } as SxProps)
      : styles.iconButton;

  const rootStyles = (disabled?: boolean): SxProps =>
    disabled
      ? ({ ...styles.root, ...styles.rootDisabled } as SxProps)
      : styles.root;

  return (
    <Box sx={rootStyles(disabled)} onClick={() => setOpenFilter(!openFilter)}>
      {typeof title === "string" ? (
        <Typography
          variant="buttonText"
          color={
            disabled
              ? "mainPalette.placeholderText"
              : "mainPalette.primaryBlack"
          }
          sx={gradientTitle && openFilter ? styles.gradientTitle : null}
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      <IconButton sx={buttonStyles(openFilter)} disableRipple>
        <ArrowDropdownIcon sx={styles.arrowIcon} />
      </IconButton>
    </Box>
  );
};
