export const getBreadCrumbsList = (pathname: string) => {
  const parts = pathname.split("/").filter(Boolean);
  let modified = false;

  const result = parts.map((part, index) => {
    let title = part.replace(/-/g, " ");
    if (modified && index === 1) {
      title = "Profile";
    }
    if (title === "Team") {
      modified = true;
    }
    return {
      id: index + 1,
      title: title,
      route: `/${parts.slice(0, index + 1).join("/")}`,
    };
  });

  return result;
};
