import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  button: {
    py: 2,
    px: 3,
    borderRadius: 2,
  },
  buttonPrimary: {
    ".MuiTypography-root": {
      color: basicTheme.palette.mainPalette.white,
    },
  },
  buttonSecondary: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: 2,
    py: 1,
    "&:hover": {
      bgcolor: "transparent",
    },
    "&&&.Mui-disabled": {
      bgcolor: "transparent",
    },
  },
};
