import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export const BreadCrumbsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3367_3566)">
      <path
        d="M13.7867 0.777962L13.7727 0.773295L11.9627 0.173296C11.3375 -0.0342665 10.6649 -0.0525882 10.0293 0.120629L6.312 1.19996C5.85684 1.32059 5.37381 1.27813 4.94667 1.07996L4.61333 0.925295C4.10706 0.714745 3.55667 0.632334 3.01093 0.685367C2.46519 0.738399 1.94096 0.925238 1.4847 1.22932C1.02844 1.53341 0.654237 1.94535 0.395256 2.42864C0.136274 2.91194 0.000511524 3.45165 0 3.99996L0 12.0499C0.00140106 12.7708 0.235861 13.4719 0.668394 14.0487C1.10093 14.6254 1.70834 15.0468 2.4 15.2499L4.31267 15.8499C4.63534 15.9503 4.97142 16.0008 5.30933 15.9999C5.61054 16.0038 5.91067 15.9631 6.2 15.8793L10.0667 14.8126C10.42 14.7168 10.7929 14.7198 11.1447 14.8213L12.7067 15.2719C13.0988 15.3668 13.5074 15.3714 13.9015 15.2854C14.2957 15.1994 14.6653 15.0251 14.9823 14.7757C15.2994 14.5262 15.5557 14.208 15.732 13.8451C15.9083 13.4822 15.9999 13.0841 16 12.6806V3.91729C15.999 3.22985 15.7859 2.55947 15.3898 1.99762C14.9937 1.43578 14.4338 1.00985 13.7867 0.777962ZM2.784 13.9726C2.36687 13.852 2.00006 13.5995 1.73853 13.2528C1.477 12.9062 1.33483 12.4842 1.33333 12.0499V3.99996C1.33009 3.66974 1.40997 3.34402 1.56562 3.05277C1.72126 2.76152 1.94767 2.5141 2.224 2.33329C2.5528 2.11519 2.93877 1.99921 3.33333 1.99996C3.58778 2.00003 3.8397 2.05033 4.07467 2.14796C4.07467 2.14796 4.57133 2.36063 4.666 2.39463V14.5613L2.784 13.9726ZM6 14.5513V2.58596C6.22644 2.57394 6.45111 2.53933 6.67067 2.48263L10 1.51529V13.4666L6 14.5513ZM14.6667 12.6806C14.6668 12.8803 14.6221 13.0775 14.5358 13.2576C14.4496 13.4377 14.3239 13.5961 14.1682 13.7211C14.0125 13.8462 13.8307 13.9347 13.6363 13.98C13.4418 14.0254 13.2396 14.0265 13.0447 13.9833L11.3333 13.5013V1.38329L13.3453 2.03596C13.732 2.17606 14.0662 2.43175 14.3026 2.76831C14.539 3.10487 14.6661 3.50601 14.6667 3.91729V12.6806Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3367_3566">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
