import { FC } from "react";

import { SvgIconProps } from "types/styles";
import { SvgIcon } from "ui/SvgIcon";

export interface CalendarIconProps extends SvgIconProps {
  gradient?: boolean;
}

export const CalendarIcon: FC<CalendarIconProps> = ({
  gradient = false,
  ...rest
}) => (
  <SvgIcon
    {...rest}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {gradient ? (
      <>
        <g clipPath="url(#clip0_6677_85239)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.19466H12.6667C13.5504 2.19572 14.3976 2.54725 15.0225 3.17214C15.6474 3.79703 15.9989 4.64426 16 5.528V13.528C15.9989 14.4117 15.6474 15.259 15.0225 15.8838C14.3976 16.5087 13.5504 16.8603 12.6667 16.8613H3.33333C2.4496 16.8603 1.60237 16.5087 0.97748 15.8838C0.352588 15.259 0.00105857 14.4117 0 13.528V5.528C0.00105857 4.64426 0.352588 3.79703 0.97748 3.17214C1.60237 2.54725 2.4496 2.19572 3.33333 2.19466H4V1.52799C4 1.35118 4.07024 1.18161 4.19526 1.05659C4.32029 0.931566 4.48986 0.861328 4.66667 0.861328C4.84348 0.861328 5.01305 0.931566 5.13807 1.05659C5.2631 1.18161 5.33333 1.35118 5.33333 1.52799V2.19466H10.6667V1.52799C10.6667 1.35118 10.7369 1.18161 10.8619 1.05659C10.987 0.931566 11.1565 0.861328 11.3333 0.861328C11.5101 0.861328 11.6797 0.931566 11.8047 1.05659C11.9298 1.18161 12 1.35118 12 1.52799V2.19466ZM1.91912 4.11378C1.54405 4.48885 1.33333 4.99756 1.33333 5.528V6.19466H14.6667V5.528C14.6667 4.99756 14.456 4.48885 14.0809 4.11378C13.7058 3.73871 13.1971 3.52799 12.6667 3.52799H3.33333C2.8029 3.52799 2.29419 3.73871 1.91912 4.11378ZM1.91912 14.9422C2.29419 15.3173 2.8029 15.528 3.33333 15.528H12.6667C13.1971 15.528 13.7058 15.3173 14.0809 14.9422C14.456 14.5671 14.6667 14.0584 14.6667 13.528V7.528H1.33333V13.528C1.33333 14.0584 1.54405 14.5671 1.91912 14.9422ZM9 10.8613C9 11.4136 8.55229 11.8613 8 11.8613C7.44772 11.8613 7 11.4136 7 10.8613C7 10.309 7.44772 9.86133 8 9.86133C8.55229 9.86133 9 10.309 9 10.8613ZM4.6665 11.8613C5.21879 11.8613 5.6665 11.4136 5.6665 10.8613C5.6665 10.309 5.21879 9.86133 4.6665 9.86133C4.11422 9.86133 3.6665 10.309 3.6665 10.8613C3.6665 11.4136 4.11422 11.8613 4.6665 11.8613ZM12.3335 10.8613C12.3335 11.4136 11.8858 11.8613 11.3335 11.8613C10.7812 11.8613 10.3335 11.4136 10.3335 10.8613C10.3335 10.309 10.7812 9.86133 11.3335 9.86133C11.8858 9.86133 12.3335 10.309 12.3335 10.8613Z"
            fill="url(#paint0_linear_6677_85239)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6677_85239"
            x1="-3.32872e-09"
            y1="8.86133"
            x2="16"
            y2="8.86133"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2825B7" />
            <stop offset="1" stopColor="#0F1528" />
          </linearGradient>
          <clipPath id="clip0_6677_85239">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.861328)"
            />
          </clipPath>
        </defs>
      </>
    ) : (
      <>
        <g clipPath="url(#clip0_6514_24258)">
          <path
            d="M12.6667 1.33333H12V0.666667C12 0.489856 11.9298 0.320286 11.8047 0.195262C11.6797 0.0702379 11.5101 0 11.3333 0C11.1565 0 10.987 0.0702379 10.8619 0.195262C10.7369 0.320286 10.6667 0.489856 10.6667 0.666667V1.33333H5.33333V0.666667C5.33333 0.489856 5.2631 0.320286 5.13807 0.195262C5.01305 0.0702379 4.84348 0 4.66667 0C4.48986 0 4.32029 0.0702379 4.19526 0.195262C4.07024 0.320286 4 0.489856 4 0.666667V1.33333H3.33333C2.4496 1.33439 1.60237 1.68592 0.97748 2.31081C0.352588 2.93571 0.00105857 3.78294 0 4.66667L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H12.6667C13.5504 15.9989 14.3976 15.6474 15.0225 15.0225C15.6474 14.3976 15.9989 13.5504 16 12.6667V4.66667C15.9989 3.78294 15.6474 2.93571 15.0225 2.31081C14.3976 1.68592 13.5504 1.33439 12.6667 1.33333ZM1.33333 4.66667C1.33333 4.13623 1.54405 3.62753 1.91912 3.25245C2.29419 2.87738 2.8029 2.66667 3.33333 2.66667H12.6667C13.1971 2.66667 13.7058 2.87738 14.0809 3.25245C14.456 3.62753 14.6667 4.13623 14.6667 4.66667V5.33333H1.33333V4.66667ZM12.6667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V6.66667H14.6667V12.6667C14.6667 13.1971 14.456 13.7058 14.0809 14.0809C13.7058 14.456 13.1971 14.6667 12.6667 14.6667Z"
            fill="currentColor"
          />
          <path
            d="M8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
            fill="currentColor"
          />
          <path
            d="M4.6665 11C5.21879 11 5.6665 10.5523 5.6665 10C5.6665 9.44772 5.21879 9 4.6665 9C4.11422 9 3.6665 9.44772 3.6665 10C3.6665 10.5523 4.11422 11 4.6665 11Z"
            fill="currentColor"
          />
          <path
            d="M11.3335 11C11.8858 11 12.3335 10.5523 12.3335 10C12.3335 9.44772 11.8858 9 11.3335 9C10.7812 9 10.3335 9.44772 10.3335 10C10.3335 10.5523 10.7812 11 11.3335 11Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_6514_24258">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </>
    )}
  </SvgIcon>
);
