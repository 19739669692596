import { CheckboxFilterType } from "types/api";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;

export const passwordValidationRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const getCheckedItemsCountInfo = (checkboxList: CheckboxFilterType[]) => {
  const checkedCount = checkboxList.filter((item) => item.checked);
  const checkedNames = checkedCount.map((item) => item.label);
  return {
    amount: checkedCount.length,
    checked: checkedNames.join(", "),
  };
};

export const titlesToolTips = {
  overview: {
    title: "Overview",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  missions: {
    title: "Missions",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  teamSkills: {
    title: "Team Skills",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  people: {
    title: "People",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  profile: {
    title: "Profile",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  profileSkills: {
    title: "Profile Skills",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  salesJournal: {
    title: "Sales Journal",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  aiInbox: {
    title: "AI Inbox",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
  peopleSource: {
    title: "People/Source",
    subtitle:
      "During the communication, the client was asked clarifying questions that allowed us to understand the client's company and the initial request and ensure we could potentially satisfy it.",
  },
};
