import { basicTheme } from "theme";
import { SxStyles } from "types/styles";

export const styles: SxStyles = {
  container: {
    background: basicTheme.palette.mainPalette.primaryGrey,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    minHeight: 74,
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      background: basicTheme.palette.gradientsPalette.gradient4,
      transition: ".3s ease-in-out",
    },
  },
  containerActive: {
    background: basicTheme.palette.mainPalette.primaryBlack,
    cursor: "default",
    pointerEvents: "none",
    "&:hover": {
      background: basicTheme.palette.mainPalette.primaryBlack,
    },
  },
  root: {
    width: "calc(100% - 2px)",
    height: "calc(100% - 2px)",
    background: basicTheme.palette.mainPalette.primaryGrey,
    p: 5,
    display: "flex",
    columnGap: 2,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  rootShort:{
    px: 3,
  },
  rootActive: {
    background: basicTheme.palette.mainPalette.primaryBlack,
  },
  userImage: {
    width: 48,
    height: 48,
    borderRadius: 2,
    overflow: "hidden",
  },
  shortUserImage: {
    width: 32,
    height: 32,
    mx: "auto",
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  userNameBox: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 0.5,
  },
  userNameActive: {
    color: basicTheme.palette.accentPalette.green1,
  },
  hoverUserName: {
    background: basicTheme.palette.gradientsPalette.gradient4,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  companyName: {
    color: basicTheme.palette.mainPalette.secondaryText,
  },
  hoverCompanyName: {
    background: basicTheme.palette.gradientsPalette.gradient4,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  companyNameActive: {
    color: basicTheme.palette.accentPalette.green1,
  },
};
